// import logo from './logo.svg';
// import './App.css';
// import Navbar from './components/Navbar';
// import Video from './components/Video';
// import Footer from './components/Footer';
// function App() {
//   return (
//     <div className="App">
//       <Navbar/>
//       <Video/>
//       <Footer/>
//     </div>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Video from './components/Video';
import Footer from './components/Footer';
import First from './components/First';
import Second from './components/Second';
import Third from './components/Third';
import E_book from './components/E_book';
import Gallery from './components/Gallery';
// import Home from './components/Home';
// import About from './components/About';
// import Margins from './components/Margins';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <h1>Four Days Live Training Video</h1>
        <Video/>
        <div className="content">
          <Routes>
            {/* <Route path="/first" element={<First />} /> */}
            <Route path="/second" element={<Second />} />
            <Route path="/third" element={<Third />} />
            <Route path="/ebook" element={<E_book />} />
            {/* <Route path="/" element={<Home />} />  */}
          </Routes>
        </div>
        <Gallery/>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
