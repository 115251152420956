// import React from 'react';
// import './Navbar.css';

// const Navbar = () => {
//     return (
//         <>
//             <div className="header">
//                 <h1>Amazon Training</h1>
//             </div>
//             <div className="navbar">
//                 <div className="logo">
//                     <img src="https://saumiccraft.com/wp-content/uploads/2023/06/S-C-Logo-300x205.png" alt="Logo" />
//                     {/* <span>Saumit Craft</span> */}
//                 </div>
//                 <nav>
//                     <a href="/first">7 Days Training</a>
//                     <a href="/second">75 Days Training</a>
//                     <a href="/third">Previous 4 Days Training </a>
//                     <a href="/ebook">E-Book </a>


//                 </nav>
//             </div>
//         </>
//     );
// }

// export default Navbar;


// import React, { useState } from 'react';
// import './Navbar.css';

// const Navbar = () => {
//     const [showNavLinks, setShowNavLinks] = useState(false);

//     const toggleNavLinks = () => {
//         setShowNavLinks(!showNavLinks);
//     };

//     return (
//         <>
//             <div className="header">
//                 <h1>Amazon Training</h1>
//             </div>
//             <div className="navbar">
//                 <div className="logo">
//                     <img src="https://saumiccraft.com/wp-content/uploads/2023/06/S-C-Logo-300x205.png" alt="Logo" />
//                     {/* <span>Saumit Craft</span> */}
//                 </div>
//                 <nav className={showNavLinks ? 'nav-links active' : 'nav-links'}>
//                     <a href="/first">7 Days Training</a>
//                     <a href="/second">75 Days Training</a>
//                     <a href="/third">Previous 4 Days Training</a>
//                     <a href="/ebook">E-Book</a>
//                 </nav>
//                 <div className="toggle-button" onClick={toggleNavLinks}>
//                     <div className={showNavLinks ? 'toggle-line active' : 'toggle-line'}></div>
//                     <div className={showNavLinks ? 'toggle-line active' : 'toggle-line'}></div>
//                     <div className={showNavLinks ? 'toggle-line active' : 'toggle-line'}></div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default Navbar;

import React, { useState } from 'react';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1rem 2rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const Heading = styled.h1`
  margin: 0;
  font-size: 24px;
  color: black;
`;

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 40px;
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: ${props => (props.show ? 'flex' : 'none')};
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    // background-color: #e74c3c;
    color: Red;
  }
`;

const ToggleButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const Navbar = () => {
  const [showNavLinks, setShowNavLinks] = useState(false);

  const toggleNavLinks = () => {
    setShowNavLinks(!showNavLinks);
  };

  return (
    <>
      <NavbarContainer>
        <Header>
          <Heading>AMAZON TRAINING</Heading>
        </Header>
        <NavbarContent>
          <Logo>
            <LogoImage src="https://saumiccraft.com/wp-content/uploads/2023/06/S-C-Logo-300x205.png" alt="Logo" />
          </Logo>
          <ToggleButton onClick={toggleNavLinks} style={{marginTop:"3rem"}}>
            ☰
          </ToggleButton>
        </NavbarContent>
        <NavLinks show={showNavLinks}>
        <NavLink href="/">Home</NavLink>

          <NavLink href="https://saumiccraft.com/amazontrainingbysaumiccraft/">7 Days Training</NavLink>
          <NavLink href="https://saumiccraft.com/amazon75daystraining/">75 Days Training</NavLink>
          <NavLink href="https://saumiccraft.com/4-days-live-work-shop/">Previous 4 Days Training</NavLink>
          <NavLink href="https://saumiccraft.com/wp-content/uploads/2023/12/ebook-amazon-book-by-Saumic-Craft-1.pdf">E-Book</NavLink>
        </NavLinks>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
